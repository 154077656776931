<template>
    <div class="userStockListHome">
        <UserStockWatchingListMobile v-if="$vuetify.breakpoint.mobile" :id="this.id" :list="this.list" :stockId="this.stockId"></UserStockWatchingListMobile>
        <UserStockWatchingList v-else :id="this.id" :list="this.list"></UserStockWatchingList>
    </div>
</template>

<script>
// @ is an alias to /src
import UserStockWatchingList from '../components/userStockWatchingList/UserStockWatchingList.vue'
import UserStockWatchingListMobile from '../components/userStockWatchingList/UserStockWatchingListMobile.vue'

export default {
    props: ['id', 'list', 'stockId'],
    name: 'userStockList',
    components: {
        UserStockWatchingList,
        UserStockWatchingListMobile
    }
}
</script>

<style lang="scss" scoped>
.userStockListHome{
    padding: 20px 0;
}
@media screen and (max-width: 750px) {
    .userStockListHome {
        padding: 10px 8px 20px;
    }
}
</style>