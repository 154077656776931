<template>
    <div class="subAndBillSection">
        <div class="subscriptionPart">
            <div class="sbInfo">
                <div class="subscriptionInfo">
                    <SubscrptionInfos></SubscrptionInfos>
                </div>
                <div class="sbAction">
                    <v-btn
                        color="blue-grey"
                        fab
                        small
                        dark
                        @click="openSubscriptionPanel()"
                    >
                        <font-awesome-icon :icon="icons.edit" class="fa-lg"></font-awesome-icon>
                    </v-btn>
                </div>
            </div>
            <SubscriptionEnd></SubscriptionEnd>
        </div>
        <div v-if="showBillingPart" class="billingPart">
            <div class="sbInfo">
                <div class="sbLabel">
                    {{$t('account.sections.subscriptionBilling.paymentMethod')}}
                    <span v-if="paymentMethodMessage" class="sip-note">{{paymentMethodMessage}}</span>
                </div>
                <div v-if="ccNumber" class="sbValue">
                    <font-awesome-icon :icon="icons.cc[ccBrand]" class="fa-2x"></font-awesome-icon>
                    <span class="ccNumber">{{ccNumber}}</span>
                </div>
                <div v-else class="sbValue">
                    <span class="ccNumber">{{$t('account.sections.subscriptionBilling.noPaymentMethod')}}</span>
                </div>
                <div class="sbAction">
                    <ManagePaymentMethods @defaultPayementMethodChanged="defaultPayementMethodChanged" @newPaymentMethodAdded="newPaymentMethodAdded"></ManagePaymentMethods>
                </div>
            </div>
            <div v-if="hasNextBillingDate" class="sbInfo">
                <div class="sbLabel">{{$t('account.sections.subscriptionBilling.nextBillingDate')}}</div>
                <div class="sbValue">{{nextBillingDate}}</div>
            </div>
            <div v-if="canCancel" class="resignSubscription">
                <CancelSubscription @cancelled="subscriptionCancelled"></CancelSubscription>
            </div>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import { faCcAmex, faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import SubscrptionInfos from "./SubscrptionInfos.vue";
import { authComputed } from "../../../store/helpers/AuthenticationHelper";
import CancelSubscription from "./CancelSubscription.vue";
import { authorizationComputed } from "../../../store/helpers/AuthorizationHelper";
import { faRectangleLandscape } from "@fortawesome/pro-light-svg-icons";
import SubscriptionEnd from "../../../components/subscriptions/SubscriptionEnd.vue";
import ManagePaymentMethods from "./ManagePaymentMethods.vue";
import DateHelper from '../../../infra/DateHelper';
import PreferencesService from "../../../services/PreferencesService";
import Alert from "../../../components/controls/Alert.vue";
export default {
    data() {
        return {
            overlay: false,
            icons: {
                edit: faPen,
                cc: {
                    unk: faRectangleLandscape,
                    mastercard: faCcMastercard,
                    visa: faCcVisa,
                    amex: faCcAmex
                }
            },
            subscription: undefined,
            defaultPaymentMethod: undefined,
            subscriptionLoaded: false
        }
    },
    computed: {
        ...authComputed,
        ...authorizationComputed,
        showBillingPart() {
            return !this.hasRole('demonstration')
        },
        hasNextBillingDate() {
            if (!this.subscriptionLoaded) return false
            return this.isCurrentCycleInfoLoaded() ? this.userSubscriptionInfo.currentCycleInfo.renewalDate : false;
        },
        nextBillingDate() {
            if (!this.subscriptionLoaded) return false
            if (this.isCurrentCycleInfoLoaded() && this.hasActiveSubscription()) {
                return DateHelper.toLongDate({ stringDate: this.userSubscriptionInfo.currentCycleInfo.renewalDate, locale: this.$i18n.locale });
            }
            else {
                return this.$t('account.sections.subscriptionBilling.noNextBillingDate');
            }
        },
        ccBrand() {
            return this.defaultPaymentMethod ? this.defaultPaymentMethod.Brand : 'unk';
        },
        ccNumber() {
            return this.defaultPaymentMethod ? `••${this.defaultPaymentMethod.Last4}` : '';
        },
        canCancel() {
            return this.userSubscriptionInfo.status === 'active' && !this.userSubscriptionInfo.terminationInfo.isCancel;
        },
        paymentMethodMessage() {
            let msg = ''
            if (this.defaultPaymentMethod) {
                if (this.defaultPaymentMethodHasExpired()) {
                    msg = this.$t('account.sections.subscriptionBilling.expiredCard')
                }
            }
            return msg
        },
        storedDefaultPaymentMethod() {
            return this.$store.getters.getDefaultPaymentMethod
        }
    },
    beforeMount() {
        if (Object.keys(this.$store.getters.getPaymentMethods).length === 0) {
            this.$store.dispatch('loadPaymentMethods').then(() => {
                this.defaultPaymentMethod = this.$store.getters.getDefaultPaymentMethod
            })
        }
        else {
            this.defaultPaymentMethod = this.$store.getters.getDefaultPaymentMethod
        }
    },
    mounted() {
        if (this.userSubscriptionInfo.defaultPaymentMethod) {
            this.defaultPaymentMethod = this.userSubscriptionInfo.defaultPaymentMethod
            this.subscriptionLoaded = true
        }
        else {
            this.loadUserSubscriptionInfo()
        }
    },
    components: { SubscrptionInfos, CancelSubscription, SubscriptionEnd, ManagePaymentMethods, Alert },
    methods: {
        loadUserSubscriptionInfo() {
            this.$store.dispatch('getUserSubscriptionInfo').then(response => {
                this.defaultPaymentMethod = response.subscription.defaultPaymentMethod;
                this.subscriptionLoaded = true
            });
        },
        defaultPayementMethodChanged({ defaultCardId }) {
            console.log(defaultCardId)
            this.$store.dispatch('updateDefaultPayementMethod', { defaultPaymentMethodId: defaultCardId })
            //this.loadUserSubscriptionInfo()
        },
        isCurrentCycleInfoLoaded() {
            return this.userSubscriptionInfo && this.userSubscriptionInfo.currentCycleInfo;
        },
        hasActiveSubscription() {
            return this.userSubscriptionInfo ? this.userSubscriptionInfo.status === 'active' && !this.userSubscriptionInfo.terminationInfo.isCancel : false;
        },
        openSubscriptionPanel() {
            this.$router.push({ name: 'Subscription' }).catch(() => { });
        },
        subscriptionCancelled({ updatedSubscription }) {
            console.log(`Fin : ${updatedSubscription.terminationInfo.terminationDate}`)
        },
        defaultPaymentMethodHasExpired() {
            const expDate = new Date(this.defaultPaymentMethod.ExpYear, this.defaultPaymentMethod.ExpMonth - 1, 1)
            return Date.parse(expDate) < Date.parse(DateHelper.getSystemDate())
        },
        newPaymentMethodAdded({ paymentMethod }) {
            this.overlay = true
            this.checkforNewDefaultPaymentMethodInfo({ timeout: 15000, paymentMethod }).then((result) => {
                this.defaultPaymentMethod = result.paymentMethods.methods.find(m => m.Id === result.defaultPaymentMethodId)
                this.$store.dispatch('setDefaultPaymentMethod', { defaultPaymentMethod: this.defaultPaymentMethod })
            }).catch(errorCode => {
                this.$refs.iAlert.error({ errorCode });
            }).finally(() => {
                this.overlay = false
            })
        },
        delay(t) {
            return new Promise((resolve) => {
                setTimeout(resolve, t);
            })
        },
        checkforNewDefaultPaymentMethodInfo({ timeout, paymentMethod = null }) {
            var me = this;
            var start = Date.now();
            const preferencesService = new PreferencesService();
            const defaultPaymentMethodId = paymentMethod || this.defaultPaymentMethod.Id
            function check() {
                var now = Date.now();
                if (now - start > timeout) {
                    return Promise.reject('NewPaymentMethodAddedTimeout');
                }
                return preferencesService.getPaymentMethodsInfo().then(response => {
                    if (defaultPaymentMethodId === response.defaultPaymentMethodId) {
                        return response
                    }
                    else {
                        return me.delay(750).then(check);
                    }
                }, async error => {
                    console.log(error);
                    const delayedResult = await me.delay(750)
                    return check(delayedResult)
                });
            }
            return check();
        }
    },
    watch: {
        storedDefaultPaymentMethod(val) {
            this.defaultPaymentMethod = val
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/breakpoints.scss";
.subAndBillSection{
    display: flex;
    flex-direction: column;
    .subscriptionPart{
        display: flex;
        flex-direction: column;
    }
    .billingPart{
        padding-top: 10px;
        margin-top: 10px;
        border-top: solid 1px #b1bfce;
        .resignSubscription {
            margin-top: 10px;
        }
    }
    .sbInfo {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        &:hover {
            background-color: var(--hoverBG);
        }
        >*:not(:last-child) {
            margin-right: 15px;
        }
        .subscriptionInfo {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        .sbLabel{
            flex: 1;
        }
        .sbValue {
            font-weight: bold;
            display: flex;
            align-items: center;
        }
        .ccNumber{
            margin-left: 10px;
        }
    }
    @media screen and (max-width: $tablet) {
        .sbLabel {
            font-size: smaller;
        }
    }
}
</style>
