<template>
    <div class="lockedControl">
        <div class="lcTitle">{{ $t('lockedControl.title') }}</div>
        <div class="lcDesc" v-html="description"></div>
        <font-awesome-icon :icon="icons.locked" class="fa-fw fa-3x"></font-awesome-icon>
    </div>
</template>

<script>
import { faLock } from '@fortawesome/pro-regular-svg-icons';

export default {
    props: ['minPlan'],
    data() {
        return {
            icons: {
                locked: faLock
            }
        }
    },
    computed: {
        description() {
            const pkey = this.minPlan ? this.minPlan.name : 'base'
            const pname = this.$t(`subscriptions.plans.${pkey}.title`)
            return this.$t('lockedControl.text', [pname])
        }
    }
}
</script>

<style lang="scss" scoped>
.lockedControl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 15px;
    .lcTitle {
        font-size: 1.2em;
        font-weight: bold;
    }
    .lcDesc {
        text-align: center;
        font-size: 0.8em;
        color: var(--midGray);
        ::v-deep span {
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}
</style>