<template>
    <div class="StockAiAnalysis">
        <div v-if="hasAiPlan" class="main">
            <div class="section">
                <SyPanel :title="$t('aiAnalysis.sentiments.title')" icon="sentiments">
                    <StockSentimentsPanel></StockSentimentsPanel>
                </SyPanel>
                <SyPanel :title="$t('aiAnalysis.similarCompanies.title')" icon="company">
                    <CompanyPanel></CompanyPanel>
                </SyPanel>
            </div>
            <div class="section">
                <SyPanel :title="$t('aiAnalysis.partnerships.title')" icon="partnership">
                    <PartnershipsPanel></PartnershipsPanel>
                </SyPanel>
                <SyPanel :title="$t('aiAnalysis.questionsBeforeInvesting.title')" icon="questions">
                    <QuestionsBeforeInvestingPanel></QuestionsBeforeInvestingPanel>
                </SyPanel>
            </div>
        </div>
        <LockedControl v-else :minPlan="minPlan"></LockedControl>
        <v-overlay :value="showOverlay" :absolute="true">
            <div class="overlayMsg">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
                <div class="msg">{{ $t('stockPage.aiLoadingOverlay.msg') }}</div>
            </div>
        </v-overlay>
    </div>
</template>

<script>
import QuestionsBeforeInvestingPanel from '../controls/AI/QuestionsBeforeInvestingPanel.vue';
import CompanyPanel from '../controls/CompanyPanel.vue';
import LockedControl from '../controls/LockedControl.vue';
import PartnershipsPanel from '../controls/PartnershipsPanel.vue';
import StockSentimentsPanel from '../controls/StockSentimentsPanel.vue';
import SyPanel from '../controls/SyPanel.vue';
import StockMixin from './StockMixin';

export default {
    mixins: [StockMixin],
    components: { PartnershipsPanel, CompanyPanel, SyPanel, StockSentimentsPanel, LockedControl, QuestionsBeforeInvestingPanel },
    data() {
        return {
            overlay: false
        }
    },
    mounted() {
        console.log('StockAiAnalysis mounted', new Date());
        setTimeout(() => {
            if (!this.aiContextualInfoLoaded) {
                this.overlay = true;
            }
        }, 3000);
    },
    computed: {
        hasAiPlan() {
            const myplan = this.$store.getters.getMyPlan
            return myplan ? myplan.isAiPlan : false;
        },
        minPlan() {
            const plans = this.$store.getters.getPlans;
            return plans.find(p => {
                return Object.values(p.limit.stockContext.aiData).includes(true);
            });
        },
        aiContextualInfoLoaded() {
            return this.$store.getters.aiContextualInfoLoaded;
        },
        showOverlay() {
            return this.overlay && !this.aiContextualInfoLoaded;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/breakpoints.scss';
.StockAiAnalysis {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .main {
        display: flex;
        gap: 20px;
        .section {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
    .overlayMsg {
        width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .msg {
            font-size: 2em;
            text-align: center;
        }
    }
    @media screen and (max-width: $small){
        .top {
            font-size: 1em;
            .nameAndSymbol {
                flex-direction: column;
                gap: 5px;
                align-items: flex-start;
            }
            .price {
                flex-direction: column;
                gap: 5px;
                align-items: flex-end;
                .spi {
                    font-size: 1.2em;
                }
            }
        }
        .main {
            flex-direction: column;
        }
    }
}
</style>