<template>
    <div class="subscriptionChangeValidation">
        <div class="validationTitle" v-show="$vuetify.breakpoint.smAndUp">{{ $t('subscriptions.stepper.2b.desc') }}</div>
        <PlanShifter></PlanShifter>
        <div class="description">
            <p v-html="upgradeOrDowngradeDescripton"></p>
            <p v-html="$t('subscriptions.stepper.2b.billingDate', [formatPrice(selectedPlanPrice), nextBillingDate])"></p>
        </div>
    </div>
</template>

<script>
import SubscriptionService from '../../services/SubscriptionService'
import PriceHelper from '../../helpers/priceHelper'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import planHelperMixin from '../../helpers/planHelperMixin'
import PlanShifter from './PlanShifter.vue'
import DateHelper from '../../infra/DateHelper'
export default {
    components: { PlanShifter },
    mixins: [planHelperMixin],
    data() {
        return {
            icons: {
                arrow: faArrowRight
            },
            priceHelper: null,
            prorata: 'N/D'
        }
    },
    created() {
        this.priceHelper = new PriceHelper()
    },
    computed: {
        selectedPlan() {
            return this.$store.getters.getSelectedPlan
        },
        selectedPlanPrice() {
            return this.selectedPlan ? this.selectedPlan.price : 0
        },
        nextBillingDate() {
            const currentCycleInfo = this.$store.getters.userSubscriptionInfo.currentCycleInfo
            const d = currentCycleInfo ? currentCycleInfo.renewalDate : new Date()
            return DateHelper.toLongDate({ stringDate: d, locale: this.$i18n.locale });
        },
        nextPlanIsDowngrade() {
            return this.$store.getters.nextPlanIsDowngrade
        },
        upgradeOrDowngradeDescripton() {
            return this.nextPlanIsDowngrade ? this.$t('subscriptions.stepper.2b.confirmToPay.downgrade', [this.nextBillingDate]) : this.$t('subscriptions.stepper.2b.confirmToPay.upgrade', [this.prorata])
        }
    },
    methods: {
        calculateSubscriptionChangeProrata({ newPlanName }) {
            return new Promise((resolve, reject) => {
                SubscriptionService.calculateSubscriptionChangeProrata({ newPlanName }).then(response => {
                    this.prorata = this.priceHelper.convertCentsToDollars(response.amountRemaining, this.$i18n.locale, 'CAD')
                    this.$store.dispatch('setNextPlanIsDowngrade', { isDowngrade: response.isDowngrade })
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        formatPrice(price) {
            return this.priceHelper.convertToDollars(price, this.$i18n.locale, 'CAD')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.subscriptionChangeValidation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background-color: #FFF;
    border: solid 1px var(--v-agora-base);
    border-radius: 5px;
    .validationTitle {
        padding: 10px;
        font-weight: bold;
        font-size: 1.2em;
    }

    .prorata {
        font-weight: bold;
    }
    .description {
        text-align: left;
        font-size: 1.1em;
        padding: 10px;
        p:last-of-type {
            margin-bottom: 0;
        }
        ::v-deep .bold {
            font-weight: bold;
        }
    }
    @media screen and (max-width: $phone) {
        font-size: 0.8em;
        .description {
            font-size: 0.8em;
        }
    }
}
</style>