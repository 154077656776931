<template>
    <div class="dashboard">
        <DashboardOverviewMobile class="mobileDO"></DashboardOverviewMobile>
        <DashboardListsMobile></DashboardListsMobile>
        <div class="dashboardBottom" @click="clickSynaxy">
            <div class="poweredby">
                <span>{{$t('Home.welcome.footer.poweredBy')}}</span>
                <v-img :src="require('@/assets/coloredLogo_whiteText.png')" contain width="138" height="45" alt="Synaxy"/>
            </div>
        </div>
    </div>
</template>

<script>
import UserSubscriptionHelper from '@/components/plan/UserSubscriptionHelper'
import { authComputed } from "@/store/helpers/AuthenticationHelper"
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { listComputed } from '@/store/helpers/ListHelper';
import UserDataHelperMixin from '../../infra/UserDataHelperMixin';
import { authorizationComputed } from '../../store/helpers/AuthorizationHelper'
import DashboardOverviewMobile from '../../components/home/dashboard/DashboardOverviewMobile.vue'
import DashboardListsMobile from '../../components/home/dashboard/DashboardListsMobile.vue'
export default {
    mixins: [UserDataHelperMixin],
    data: () => ({
        canAddBuyList: false,
        canAddSellList: false,
        userSubscriptionHelper: undefined,
        icons: {
            warning: faExclamationTriangle
        }
    }),
    components: {
        DashboardOverviewMobile,
        DashboardListsMobile
    },
    computed: {
        ...authComputed,
        ...listComputed,
        ...authorizationComputed,
        getUserSubscriptionInfo() {
            return this.userSubscriptionInfo;
        },
        hasPF() {
            return this.planHasPF
        },
        showDashboardOverview() {
            return this.planHasPF && this.planUsage.portfolioCombineEntrieCount > 0
        }
    },
    mounted() {
        this.updateInfos(this.userSubscriptionInfo);
    },
    watch: {
        getUserSubscriptionInfo: {
            handler: function (val) {
                this.updateInfos(val);
            },
            deep: true
        }
    },
    methods: {
        highlightCard({ listId }) {
            console.log(listId);
        },
        updateInfos(userSubscriptionInfo) {
            if (Object.prototype.hasOwnProperty.call(userSubscriptionInfo, "usage")) {
                this.userSubscriptionHelper = new UserSubscriptionHelper({ userSubscriptionInfo });
                this.canAddBuyList = this.userSubscriptionHelper.canAddBuyList();
                this.canAddSellList = this.userSubscriptionHelper.canAddSellList();
            }
        },
        clickSynaxy() {
            window.open('https://www.synaxy.com/', '_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.dashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--darkBG);
    height: 100%;
    .dashboardBottom {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
        .poweredby {
            font-size: small;
            display: flex;
            color: white;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &>* {
                flex-grow: 0;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        flex-direction: column;
        .lists>* {
            flex-grow: 0;
            margin-bottom: 0;
        }
        .stockList {
            width: 100%;
        }
        .cardList {
            gap: 15px;
        }
        .portfolio {
            display: none;
        }
    }
    @media screen and (max-width: $small) {
        .lists {
            padding: 8px;
            flex-direction: column;
        }
    }
}
</style>