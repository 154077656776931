import { mapGetters } from 'vuex'
import TopEntries from "../../components/home/dashboard/summary/TopEntries";

export function getListNameFromType({ listType }) {
    return listType === 'buy' ? 'buyLists' : listType === 'sell' ? 'sellLists' : 'portfolios';
}

export function getTopList(lists, count) {
    const topEntries = new TopEntries();
    lists.forEach(list => {
        var entries = list.entries;
        entries.forEach(entry => {
            topEntries.addEntry({
                stock: entry,
                list: list
            });
        });
    });
    return topEntries.getTop(count);
}

export function getTopMovers(lists, count) {
    const top = []
    lists.forEach(list => {
        list.stocks.forEach(entry => {
            top.push(entry)
        })
    });
    const merge = top.reduce((acc, cur) => {
        const existing = acc.find(e => e.stockId === cur.stockId)
        if (existing) {
            existing.statistics.dayChange += cur.statistics.dayChange
        } else {
            acc.push(cur)
        }
        return acc
    }, [])
    const sorted = merge.sort((a, b) => Math.abs(b.statistics.dayChange) - Math.abs(a.statistics.dayChange))
    return sorted.slice(0, count)
}

export const listComputed = {
    ...mapGetters(['buyListsLoaded', 'sellListsLoaded', 'portfoliosLoaded', 'userLists'])
}