<template>
    <div class="planShifter">
        <div class="planInfos">
            <div class="ptitle">{{ $t('subscriptions.stepper.2b.actualPlan') }}</div>
            <div class="planCont">
                <font-awesome-icon :icon="icons[currentPlan.name]" style="color:dodgerblue;"></font-awesome-icon>
                <div class="planName">{{ planTitle(currentPlan.name) }}</div>
            </div>
            <div class="planPrice">{{ formatPrice(currentPlan.price) }}</div>
        </div>
        <font-awesome-icon :icon="icons.arrow" class="fa-fw fa-2x"></font-awesome-icon>
        <div class="planInfos">
            <div class="ptitle">{{ $t('subscriptions.stepper.2b.newPlan') }}</div>
            <div class="planCont">
                <font-awesome-icon :icon="icons[selectedPlan.name]" style="color:dodgerblue;"></font-awesome-icon>
                <div class="planName">{{ planTitle(selectedPlan.name) }}</div>
            </div>
            <div class="planPrice">{{ formatPrice(selectedPlan.price) }}</div>
        </div>
    </div>
</template>

<script>
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import planHelperMixin from '../../helpers/planHelperMixin';
import PriceHelper from '../../helpers/priceHelper';

export default {
    mixins: [planHelperMixin],
    data() {
        return {
            icons: {
                arrow: faArrowRight
            },
            priceHelper: null,
            prorata: 'N/D',
            emptyPlan: { name: 'base', price: 0 }
        }
    },
    created() {
        this.priceHelper = new PriceHelper()
    },
    computed: {
        currentPlan() {
            return this.$store.getters.getMyPlan || this.emptyPlan
        },
        selectedPlan() {
            return this.$store.getters.getSelectedPlan || this.emptyPlan
        }
    },
    methods: {
        planTitle(planName) {
            const plan = planName || 'base'
            return this.$t(`subscriptions.plans.${plan}.title`)
        },
        formatPrice(price) {
            return this.priceHelper.convertToDollars(price, this.$i18n.locale, 'CAD')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.planShifter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .planInfos {
        padding: 15px;
        background-color: var(--v-agorabg-base);
        border: solid 1px var(--v-agora-base);
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 175px;
        * {
            text-align: center;
        }
        .ptitle {
            font-size: 0.8em;
            padding: 5px;
            border-radius: 2em;
            background-color: var(--v-agora-base);
            color: #FFF;
            margin-bottom: 10px;
        }
        .planCont {
            font-size: 1.3em;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            padding: 10px;
            .planName {
                text-transform: capitalize;
                font-weight: bold;
            }
        }
        .planPrice {
            font-size: 1.1em;
            font-weight: bold;
            color: #777;
        }
    }
    @media screen and (max-width: $phone) {
        gap: 10px;
        .planInfos {
            min-width: 120px;
            .planCont {
                flex-direction: column;
            }
        }
    }
}
</style>