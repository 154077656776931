import { faSuitcase } from '@fortawesome/pro-regular-svg-icons';
import ConfirmDialog from '../../../controls/ConfirmDialog.vue';
import StockChart from '../../../controls/StockChart.vue';
import StockHtmlHelper from '../../../stocks/StockHtmlHelper';
import StockPriceChange from '../../../stocks/StockPriceChange.vue';
import ValueAndChange from '../../../controls/ValueAndChange.vue';
import StockPriceHelperMixin from '../../../../helpers/StockPriceHelperMixin';

var portfolioCardMixin = {
    props: ['portfolio'],
    mixins: [StockPriceHelperMixin],
    data() {
        return {
            icons: {
                portfolio: faSuitcase
            },
            fab: false,
            stockHTMLHelper: null
        };
    },
    components: { StockChart, StockPriceChange, ConfirmDialog, ValueAndChange },
    created() {
        this.stockHTMLHelper = new StockHtmlHelper()
    },
    computed: {
        userCurrency() {
            return this.$store.getters.userCurrency
        },
        change() {
            const amount = this.portfolio.statistics.dayChange
            return amount > 0 ? 'up' : amount < 0 ? 'down' : 'null'
        },
        iconColor() {
            switch (this.change) {
                case 'up':
                    return '#388E3C'
                case 'down':
                    return '#C62828'
                default:
                    return '#DDD'
            }
        },
        portfolioValueChange() {
            return {
                val: this.portfolio.statistics.dayChange,
                chg: this.portfolio.statistics.dayPercentChange,
                chgSymbol: "%",
                inverted: true
            }
        },
        portfolioValueChangeConverted() {
            const convertedValue = this.convertValue(this.portfolio.statistics.dayChange, this.portfolio.currency, this.userCurrency)
            return {
                val: convertedValue,
                chg: this.portfolio.statistics.dayPercentChange,
                chgSymbol: "%"
            }
        },
        stockChangeInfo() {
            return {
                changeAmount: this.portfolio.statistics.dayChange,
                changePercent: this.portfolio.statistics.dayPercentChange
            }
        },
        portfolioCurrency() {
            const currency = this.portfolio.currency
            return currency === 'EUR' ? '€' : currency.toUpperCase()
        },
        portfolioCurrencyNotSameAsUserPref() {
            return this.portfolio.currency.localeCompare(this.userCurrency, this.$i18n.locale, { sensitivity: 'base' }) !== 0
        },
        portfolioValue() {
            const lastPrice = this.portfolio.statistics.totalRemainingStockValue
            return this.stockHTMLHelper.formatPriceWithLocaleCurrency(lastPrice, this.portfolio.currency, this.$i18n.locale)
        },
        portfolioList() {
            return this.$store.getters.portfolioList
        },
        convertedText() {
            return this.$t('Home.dashboard.lists.convertedValue', { currency: this.userCurrency })
        },
        portfolioValueConverted() {
            const lastPrice = this.portfolio.statistics.totalRemainingStockValue || 0
            const convertedValue = this.convertValue(lastPrice, this.portfolio.currency, this.userCurrency)
            return this.stockHTMLHelper.formatPriceWithLocaleCurrency(convertedValue, this.userCurrency, this.$i18n.locale)
        }
    },
    methods: {
        editPortfolioInfo() {
            this.$emit('askEditBoardInfos')
        },
        viewPortfolio() {
            this.$router.push({ name: 'Portfolio', params: { id: this.portfolio.id } });
        },
        deletePortfolio() {
            this.$refs.deletePortfolioConfirmDialog.open({
                data: { portfolioId: this.portfolio.id },
                title: this.$t('Home.dashboard.lists.actions.deletePortfolio.title'),
                text: this.$t('Home.dashboard.lists.actions.deletePortfolio.text', [this.portfolio.name])
            })
        },
        deletePortfolioDialogAnswered(answer) {
            if (answer.response === true) {
                this.$store.dispatch('deletePortfolio', answer.data).catch(error => {
                    console.log(error)
                    this.$refs.iAlert.error(error[0]);
                });
            }
        }
    }
}

export default portfolioCardMixin