<template>
    <div class="StockHoldingsPanel">
        <v-skeleton-loader v-if="!stock" type="image"></v-skeleton-loader>
        <div class="holding" v-for="(sh, i) in stockHoldings" :key="i">
            <SyPanel :title="sh.listName" icon="portfolio" :clickable="true" @titleClicked="viewPortfolio(sh.portfolioId)">
                <PortfolioStockLotWidgetMobile v-if="isMobile" :portfolioId="sh.portfolioId" :stockId="sh.stockId" :stockinfo="sh"></PortfolioStockLotWidgetMobile>
                <PortfolioStockLotWidget v-else :portfolioId="sh.portfolioId" :stockLots="sh.lots" :stock="sh" class="pfHoldings"></PortfolioStockLotWidget>
            </SyPanel>
        </div>
    </div>
</template>

<script>
import SyPanel from '../controls/SyPanel.vue'
import StockMixin from '../stocks/StockMixin'
import PortfolioStockLotWidget from './PortfolioStockLotWidget.vue'
import PortfolioStockLotWidgetMobile from './PortfolioStockLotWidgetMobile.vue'
export default {
    components: { PortfolioStockLotWidget, PortfolioStockLotWidgetMobile, SyPanel },
    mixins: [StockMixin],
    computed: {
        stockHoldings() {
            if (!this.$store.getters.allListAreLoaded) return []
            const sh = Object.keys(this.$store.getters.userLists).reduce((acc, cur) => {
                if (cur === 'portfolios') {
                    const lists = this.$store.getters.userLists[cur]
                    lists.forEach(list => {
                        list.stocks.forEach(s => {
                            if (s.stockId === this.stockId) {
                                s.listName = list.name
                                acc.push(s)
                            }
                        })
                    })
                }
                return acc
            }, [])
            return sh
        }
    },
    methods: {
        viewPortfolio(portfolioId) {
            this.$router.push({ name: 'Portfolio', params: { id: portfolioId } })
        }
    }
}
</script>

<style lang="scss" scoped>
.StockHoldingsPanel {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .holding {
        .pfHoldings {
            z-index: 1;
        }
    }
}
</style>