<template>
    <div class="invaList">
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="blue darken-4"
                    fab
                    dark
                    absolute
                    top
                    right
                    style="top:-10px; right:40px;"
                    class="d-none d-sm-flex"
                    @click="addStock"
                    v-bind="attrs"
                    v-on="on"
                >
                    <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                </v-btn>
            </template>
            <span>{{$t('UserStockWatchingList.tableHeader.addTooltip')}}</span>
        </v-tooltip>
        <v-data-table
            :headers="headers"
            :items="stocksToShow"
            item-key="name"
            :search="search"
            sort-by="absoluteScore"
            :sort-desc=true
            :items-per-page=-1
            locale="fr-CA"
            :header-props="{ sortIcon: null }"
            @click:row="showStock"
            class="elevation-1 stockTable">
            <template v-slot:top>
                <v-toolbar flat height="72" color="white">
                    <div class="listName">
                        <v-select
                            v-model="listSelected"
                            :items="listNames"
                            item-text="name"
                            item-value="id"
                            rounded
                            dense
                            outlined
                            hide-details="true"
                            @change="changeList"
                        >
                            <template slot="item" slot-scope="data">
                                <font-awesome-icon :icon="getListIconFromType(data.item.type)" class="fa-fw me-3" />
                                <div class="cb-item">{{data.item.name}}</div>
                            </template>
                        </v-select>
                    </div>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:item.symbol="{ item }">
                <div class="entrySymbollInfo">
                    <div class="entrySymbol">{{item.stockDateTimeSnapshot.symbol}}</div>
                    <StockCountry v-if="false" :country="item.country"></StockCountry>
                </div>
            </template>
            <template v-slot:item.compagnyName="{ item }">
                <span >{{item.stockDateTimeSnapshot.compagnyName}}</span>
                <!-- <span :class="companyNameTagClass(item.companyName)">{{displayCompanyName(item.companyName)}}</span> -->
            </template>
            <template v-slot:item.price="{ item }">
                <StockPriceInfos :entry="stockPriceInfo(item)" :key="item.id + '-espi'"></StockPriceInfos>
            </template>
            <template v-slot:item.change="{ item }">
                <StockPriceChange :stockChangeInfo="stockChangeInfo(item.stockDateTimeSnapshot)"></StockPriceChange>
            </template>
            <template v-slot:item.dateTime="{ item }">
                <StockPriceDate :entry="item.dateInfos" out="end"></StockPriceDate>
            </template>
            <template v-slot:item.shares="{ item }">
                <span>{{item.statistics.remainingStockShareCount}}</span>
            </template>
            <template v-slot:item.avgBuyPrice="{ item }">
                <span>{{formatPrice(item.statistics.actualAverageShareBuyPrice)}}</span>
            </template>
            <template v-slot:item.totalChange="{ item }">
                <span :class="changeClass(item.statistics.totalProfitIncludingUnrealized)">{{formatChange(item.statistics.totalProfitIncludingUnrealized, item.stockDateTimeSnapshot.price)}}</span>
            </template>
            <template v-slot:item.totalChangePrct="{ item }">
                <span :class="changeClass(item.statistics.percentageOfProfitIncludingUnrealized)">{{formatChange(item.statistics.percentageOfProfitIncludingUnrealized, item.stockDateTimeSnapshot.price)}}%</span>
            </template>
            <template v-slot:item.marketValue="{ item }">
                <span class="marketValue">{{formatPrice(item.statistics.totalRemainingStockValue)}}</span>
            </template>
            <template v-slot:item.marketCap="{ item }">
                <span>{{AdaptIfNull(item.stockDateTimeSnapshot.marketCap)}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="actionBtn" @click.stop="editStock(item)">mdi-pencil</v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Portfolio from '../../models/Portfolio'
import StockCountry from '../stocks/StockCountry.vue'
import StockPriceInfos from '../stocks/StockPriceInfos.vue'
import userStockListMixin from '../userStockWatchingList/UserStockListMixin'
export default {
    components: { StockCountry, StockPriceInfos },
    props: ['portfolioStock'],
    mixins: [userStockListMixin],
    data() {
        return {
            expanded: [],
            singleExpand: false,
            dialog: false,
            stocks: [],
            editedIndex: -1,
            search: undefined,
            listSelected: this.$route.params.id,
            //listNames: undefined,
            editedItem: {
                symbol: '',
                name: ''
            },
            defaultItem: {
                symbol: '',
                name: ''
            }
        }
    },
    computed: {
        headers() {
            return [
                { text: this.$t('UserStockWatchingList.watchTable.headers.symbol'), value: 'symbol', class: 'colHeader', width: '5%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.companyName'), value: 'compagnyName', class: 'colHeader', width: '20%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.marketRegularPrice'), value: 'price', class: "lastprice colHeader", align: 'right', width: '7%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.change'), value: 'change', class: 'colHeader', align: 'right', width: '10%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.priceSince'), value: 'dateTime', class: 'colHeader', align: 'right', width: '8%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.shares'), value: 'shares', class: 'colHeader', align: 'right', width: '8%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.avgBuyPrice'), value: 'avgBuyPrice', class: 'colHeader', align: 'right', width: '8%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.totalChange'), value: 'totalChange', class: 'colHeader', align: 'right', width: '8%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.totalChangePrct'), value: 'totalChangePrct', class: 'colHeader', align: 'right', width: '8%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.marketValue'), value: 'marketValue', class: 'colHeader', align: 'right', width: '10%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.marketCap'), value: 'marketCap', class: 'colHeader', align: 'right', width: '8%' },
                { text: '', value: 'actions', class: 'colHeader', align: 'right' }
            ]
        },
        stocksToShow() {
            return this.portfolioStock ? this.portfolioStock.stocks.filter(s => s.stockDateTimeSnapshot) : []
        }
    },
    methods: {
        changeList() {
            const list = this.allLists.find(l => l.id === this.listSelected)
            if (list instanceof Portfolio) {
                this.$router.push({ name: 'Portfolio', params: { id: this.listSelected } });
            } else {
                this.$router.push({ name: 'List', params: { id: this.listSelected } });
            }
        },
        addStock() {
            this.$emit('addStock')
        },
        showStock(stock) {
            this.$store.dispatch('setStockPageOrigin', { stockPageOrigin: this.listSelected });
            this.$router.push({ name: 'Stock', params: { id: stock.stockId } });
        },
        editStock(item) {
            this.$emit('editStock', item)
        },
        stockPriceInfo(stock) {
            return {
                currency: stock.stockDateTimeSnapshot.currency.toUpperCase(),
                lastPrice: stock.stockDateTimeSnapshot.price
            }
        },
        stockChangeInfo(stock) {
            return {
                price: stock.price,
                changeAmount: stock.change,
                changePercent: stock.changePercent
            }
        },
        formatChange(change, price) {
            let formattedPrice = '';
            if (price && price !== 0) {
                const p = change.toFixed(price < 5 ? 4 : 2);
                const ps = p.split('.');
                formattedPrice = Number(ps[0]).toLocaleString() + '.' + ps[1];
            }
            return formattedPrice;
        },
        changeClass(change) {
            return `change${change < 0 ? ' negative' : change > 0 ? ' positive' : ''}`
        },
        shareCount(stock) {
            return stock.lots.reduce((acc, cur) => {
                return acc + cur.quantity
            }, 0)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.invaList {
    .stockTable {
        ::v-deep tbody tr :hover {
            cursor: pointer;
        }
    }
    .entrySymbollInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .entrySymbol{
            font-weight: bold;
        }
    }
    .marketValue {
        font-weight: bold;
    }
    .change {
        font-weight: bold;
        &.positive {
            color: var(--v-greenUp-base);
        }
        &.negative {
            color: var(--v-redDown-base);
        }
    }
}
</style>