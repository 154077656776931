<template>
    <div class="stockPageHeader">
        <div v-if="stock" :class="`stockHeader ${extraStockHeaderCss}`">
            <div class="nameAndSymbol"><span>{{ stockName }}</span><v-chip dark color="agora">{{ stockSymbol }}</v-chip></div>
            <div class="logo">
                <v-img v-if="logo" :src="logo" contain max-height="200" max-width="200" @load="imgLoaded"></v-img>
            </div>
            <div class="price">
                <StockPriceInfos :entry="stock" class="spi"></StockPriceInfos>
                <StockPriceChange :stockChangeInfo="stockChangeInfo"></StockPriceChange>
            </div>
        </div>
        <v-skeleton-loader v-else type="image" height="86"></v-skeleton-loader>
    </div>
</template>

<script>
import StockHtmlHelper from '../../components/stocks/StockHtmlHelper';
import StockMixin from '../../components/stocks/StockMixin';
import StockPriceChange from '../../components/stocks/StockPriceChange.vue';
import StockPriceInfos from '../../components/stocks/StockPriceInfos.vue';

export default {
    mixins: [StockMixin],
    components: { StockPriceChange, StockPriceInfos },
    data() {
        return {
            stockHtmlHelper: null,
            extraStockHeaderCss: ''
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper()
    },
    computed: {
        logo() {
            return this.stock ? this.stockHtmlHelper.getStockImageSrc(this.stock.companyInfo.logoUrl) : null;
        }
    },
    methods: {
        imgLoaded() {
            this.extraStockHeaderCss = 'imgLoaded'
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.stockPageHeader {
    flex: 1;
    font-size: 1.5em;
    background-color: #fff;
    border: solid 1px #EEE;
    .stockHeader {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .nameAndSymbol {
            font-weight: bold;
            background-color: var(--v-lightBG-base);
            display: flex;
            align-items: center;
            gap: 20px;
        }
        .logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            height: 200px;
            opacity: 0.1;
            rotate: -5deg;
        }
        .price {
            display: flex;
            gap: 20px;
            .spi {
                font-size: 1.5em;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        .stockHeader {
            flex-direction: column;
            gap: 20px;
        }
        .logo {
            top: -30%;
        }
    }
}
</style>