import SubscriptionPlanPanel from './SubscriptionPlanPanel'
import StripeCheckoutPanel from './StripeCheckoutPanel.vue'
import OrderDetailsPanel from './OrderDetailsPanel.vue'
import CreditCardPaymentPanel from './CreditCardPaymentPanel'
import SubscriptionConfirmationPanel from './SubscriptionConfirmationPanel'
import SubscriptionChangeValidation from './SubscriptionChangeValidation.vue';
import SubscriptionChangeConfirmation from './SubscriptionChangeConfirmation.vue';
import Alert from '@/components/controls/Alert.vue'
import SubscriptionService from '@/services/SubscriptionService'
import { authComputed } from "@/store/helpers/AuthenticationHelper"
import { faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'
import { faCreditCardBlank } from '@fortawesome/pro-solid-svg-icons'
import PreferencesService from '../../services/PreferencesService'
import PaymentServiceMixin from './PaymentServiceMixin'
var SubscriptionStepperMixin = {
    mixins: [PaymentServiceMixin],
    name: 'SubscriptionStepper',
    data() {
        return {
            creditCardInfos: {},
            creditCardInfosFilled: false,
            subscriptionId: undefined,
            clientSecret: undefined,
            step: 1,
            overlay: false,
            invoice: {},
            yourCreditCards: [],
            defaultPaymentMethodId: undefined,
            creditCardSelection: undefined,
            icons: {
                cc: {
                    unk: faCreditCardBlank,
                    mastercard: faCcMastercard,
                    visa: faCcVisa,
                    amex: faCcAmex
                }
            },
            isNewOrder: false
        }
    },
    components: {
        SubscriptionPlanPanel,
        CreditCardPaymentPanel,
        SubscriptionConfirmationPanel,
        StripeCheckoutPanel,
        OrderDetailsPanel,
        SubscriptionChangeValidation,
        SubscriptionChangeConfirmation,
        Alert
    },
    created() {
        //this.selectedPlan.planName = this.planName;
        const preferencesService = new PreferencesService()
        preferencesService.getPaymentMethodsInfo().then(response => {
            const paymentMethods = response.paymentMethods.methods
            this.yourCreditCards = paymentMethods.map(pm => {
                return {
                    id: pm.Id,
                    brand: pm.Brand,
                    last4: pm.Last4,
                    expiration: {
                        year: pm.ExpYear,
                        month: pm.ExpMonth
                    }
                }
            })
            this.defaultPaymentMethodId = response.defaultPaymentMethodId
            this.creditCardSelection = this.defaultPaymentMethodId
        }).catch(errors => {
            if (errors[0].errorCode === 'UserIsNotACustomer') {
                console.log(errors)
            }
        });
    },
    mounted() {
        this.isNewOrder = this.userSubscriptionInfo.isFreePlan
    },
    computed: {
        ...authComputed,
        subscriptionInfo() {
            return {
                subscriptionId: this.subscriptionId,
                clientSecret: this.clientSecret
            }
        },
        paymentInfosContinueBtnEnabled() {
            return this.creditCardInfosFilled || (this.creditCardSelection !== undefined && this.creditCardSelection !== 'new')
        },
        continueToCheckoutBtnEnabled() {
            return !!this.$store.getters.getSelectedPlan && this.$store.getters.differentPlanSelected
        },
        selectedPlan() {
            return this.$store.getters.getSelectedPlan
        },
        selectedPlanName() {
            return this.$store.getters.selectedPlanName
        },
        userSubscriptionInfo() {
            return this.$store.getters.userSubscriptionInfo
        },
        step1Action() {
            return this.isNewOrder ? this.$t('subscriptions.plans.buttons.continueToCheckout') : this.$t('subscriptions.plans.buttons.validate')
        },
        step2Action() {
            return this.isNewOrder ? this.$t('subscriptions.plans.buttons.placeOrder') : this.$t('subscriptions.plans.buttons.confirmChanges')
        }
    },
    methods: {
        stepTitle(number) {
            const step = this.isNewOrder ? number : `${number}b`
            return this.$t(`subscriptions.stepper.${step}.title`)
        },
        cancel() {
            if (this.$refs.stripeCheckoutPanel) {
                this.$refs.stripeCheckoutPanel.clearForm();
            }
            if (this.loggedIn) {
                this.$store.dispatch('resetSelectedPlan')
            }
            this.closeAsked();
        },
        newPlanSelected({ plan }) {
            //this.selectPlanContinueBtnEnabled = plan.planName !== this.planName;
            //this.selectedPlan = plan;
            console.log('newPlanSelected1')
        },
        paymentFormChanged({ completed, creditCardInfos = {} }) {
            this.creditCardInfosFilled = completed;
            this.creditCardInfos = creditCardInfos;
        },
        closeAsked() {
            this.step = 1;
            this.$router.push({ name: 'Home' })
        },
        newPlanSelectionConfirm() {
            if (this.isNewOrder) {
                this.step = 2
            } else {
                this.$refs.subscriptionChangeValidation.calculateSubscriptionChangeProrata({ newPlanName: this.selectedPlanName }).then(() => {
                    this.step = 2
                })
            }
        },
        paymentInfosCompleted({ complete }) {
            this.creditCardInfosFilled = complete;
        },
        delay(t) {
            return new Promise((resolve) => {
                setTimeout(resolve, t);
            })
        },
        checkTokenStatus({ timeout, retryToken }) {
            var me = this;
            var start = Date.now();
            function check({ retryToken }) {
                var now = Date.now();
                if (now - start > timeout) {
                    return Promise.reject(new Error(me.$t('alerts.codes.SubscriptionConfirmationTimeout')));
                }
                return SubscriptionService.regenerateTokenToHandleSubscriptionChanged({ retryToken }).then(({ subscriptionInfos, newRetryToken }) => {
                    if (subscriptionInfos.subscriptionPlanName && subscriptionInfos.subscriptionPlanName !== me.planName) {
                        return subscriptionInfos;
                    }
                    else {
                        return me.delay(750).then(() => {
                            return check({ retryToken: newRetryToken });
                        });
                    }
                }, async error => {
                    console.log(error);
                    return me.delay(750).then(() => {
                        return check({ retryToken });
                    });
                });
            }
            return check({ retryToken });
        },
        checkNewStatus({ retryToken }) {
            this.checkTokenStatus({ timeout: 15000, retryToken }).then((result) => {
                this.$store.dispatch('getUserSubscriptionInfo');
                if (this.$refs.stripeCheckoutPanel) {
                    this.$refs.stripeCheckoutPanel.clearForm();
                }
                this.step = 3;
            }).catch((error) => {
                this.$emit('notification', { type: 'error', text: error.message });
            }).finally(() => { this.overlay = false });
        },
        confirmSubscription() {
            this.overlay = true;
            if (this.isNewOrder) {
                SubscriptionService.createSubscription({ priceId: this.selectedPlan.priceId }).then((response) => {
                    this.subscriptionId = response.subscriptionId;
                    this.invoice = response.invoice;
                    const retryToken = response.retryToken;
                    this.confirmPayment({ clientSecret: response.clientSecret }).then(() => {
                        this.checkNewStatus({ retryToken });
                    })
                    .catch(error => {
                        const errorCode = `stripe.${error.code}`
                        this.$refs.iAlert.error({ errorCode })
                        this.overlay = false
                    })
                }).catch(error => {
                    const errorCode = Array.isArray(error) ? error[0].errorCode : error.code
                    this.$refs.iAlert.error({ errorCode })
                    this.overlay = false
                })
            } else {
                SubscriptionService.upgradeOrDowngrade({ newPlanName: this.selectedPlanName }).then(response => {
                    console.log(response)
                    this.$store.dispatch('updateUserSubscriptionInfo', { userSubscriptionInfo: response.updatedSubscription })
                    this.step = 3
                }).catch(error => {
                    const errorCode = Array.isArray(error) ? error[0].errorCode : error.code
                    this.$refs.iAlert.error({ errorCode })
                }).finally(() => {
                    this.overlay = false
                })
            }
        },
        confirmPayment({ clientSecret }) {
            if (this.creditCardSelection === 'new') {
                return this.$refs.stripeCheckoutPanel.submit({ clientSecret })
            } else {
                return this.confirmPaymentWithExistingPaymentMethod({ clientSecret, existingPaymentMethodId: this.creditCardSelection })
            }
        },
        expirationDetail(expiration) {
            const today = new Date()
            const exp = new Date(expiration.year, expiration.month - 1)
            return today > exp ? this.$t('dictio.expired') : ''
        }
    }
}

export default SubscriptionStepperMixin