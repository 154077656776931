<template>
    <div class="subscriptionChangeConfirmation">
        <div class="subscriptionChangeConfirmationTitle" v-show="$vuetify.breakpoint.smAndUp">{{ $t('subscriptions.stepper.3b.title') }}</div>
        <PlanShifter></PlanShifter>
        <div class="description" v-html="changeDescripton"></div>
    </div>
</template>

<script>
import DateHelper from '../../infra/DateHelper'
import PlanShifter from './PlanShifter.vue'
export default {
    components: { PlanShifter },
    data() {
        return {
        }
    },
    computed: {
        plannedChangeOfPlan() {
            return this.$store.getters.plannedChangeOfPlan
        },
        nextPlanIsDowngrade() {
            return this.$store.getters.nextPlanIsDowngrade
        },
        canceledSubscription() {
            return this.$store.getters.canceledSubscription
        },
        changeDescripton() {
            const newPlan = this.plannedChangeOfPlan
            if (newPlan) {
                const resKey = this.canceledSubscription ? 'cancelled' : this.nextPlanIsDowngrade ? 'downgrade' : 'upgrade'
                const planName = this.$t(`subscriptions.plans.${newPlan.planName}.title`)
                const newPanDate = DateHelper.toLongDate({ stringDate: newPlan.date, locale: this.$i18n.locale });
                return this.$t(`subscriptions.stepper.3b.description.${resKey}`, [planName, newPanDate])
            }
            return this.$t('dictio.na')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.subscriptionChangeConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background-color: #FFF;
    border: solid 1px var(--v-agora-base);
    border-radius: 5px;
    .subscriptionChangeConfirmationTitle {
        padding: 10px;
        font-weight: bold;
        font-size: 1.2em;
    }
    .description {
        padding: 10px;
        font-size: 1.1em;
        ::v-deep .bold {
            font-weight: bold;
        }
    }
    @media screen and (max-width: $phone) {
        font-size: 0.8em;
        .description {
            font-size: 0.8em;
        }
    }
}
</style>