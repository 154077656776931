<template>
    <div class="syPanel">
        <div class="pInfos">
            <div v-if="clickable" class="pTitle clickable" @click="titleClicked">{{ title }}</div>
            <div v-else class="pTitle">{{ title }}</div>
            <div class="pDetail">
                <slot name="details"></slot>
            </div>
        </div>
        <div class="pBody">
            <slot></slot>
        </div>
        <div class="pFooter">
            <slot name="footer"></slot>
        </div>
        <font-awesome-icon :icon="icons[icon]" class="fa-fw bgIcon" color="#CCC"></font-awesome-icon>
    </div>
</template>

<script>
import ListRegularIconMixin from '../help/icons/ListRegularIconMixin';
export default {
    props: {
        title: {
            type: String
        },
        icon: {
            type: String
        },
        clickable: {
            type: Boolean,
            default: false
        }
    },
    mixins: [ListRegularIconMixin],
    methods: {
        titleClicked() {
            this.$emit('titleClicked');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.syPanel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-color: var(--v-lightGrayBG-base);
    position: relative;
    .pInfos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2em;
        .pTitle {
            font-weight: bold;
            z-index: 10;
            &.clickable {
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .pBody {
        position: relative;
        z-index: 1;
        background-color: #FFF;
    }
    .pFooter { }
    .bgIcon {
        position: absolute;
        top: -5px;
        right: 120px;
        font-size: 80px;
        rotate: -15deg;
    }
    @media screen and (max-width: $small){
        padding: 10px;
        .pInfos {
            font-size: 1em;
        }
        .bgIcon {
            opacity: 0.5;
        }
    }
}
</style>