class DateHelper {
    static setSystemDate(snapshotDate) {
        DateHelper.serverDateTimeSnapshot = snapshotDate
    }

    static getSystemDate() {
        return DateHelper.serverDateTimeSnapshot
    }

    static toSimpleDate(stringDate) {
        if (stringDate) {
            const d = new Date(stringDate);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('-');
        }
        return '';
    }

    static toShortDay({ stringDate, locale }) {
        if (stringDate) {
            const d = new Date(stringDate);
            const options = { day: 'numeric', month: 'short' };
            return d.toLocaleDateString(locale, options);
        }
        return '';
    }

    static toShortMonth({ stringDate, locale }) {
        if (stringDate) {
            const d = new Date(stringDate);
            const options = { month: 'short' };
            return d.toLocaleDateString(locale, options);
        }
        return '';
    }

    static toShortDayWithYear({ stringDate, locale }) {
        if (stringDate) {
            const d = new Date(stringDate);
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return d.toLocaleDateString(locale, options);
        }
        return '';
    }

    static toShortWeekday({ stringDate, locale }) {
        if (stringDate) {
            const d = new Date(stringDate);
            const options = { weekday: 'short' };
            return d.toLocaleDateString(locale, options);
        }
        return '';
    }

    static toSimpleDateTime(stringDateTime, dateTimeSeparator) {
        if (stringDateTime) {
            const d = new Date(stringDateTime);
            const hours = ('' + (d.getHours())).padStart(2, '0');
            const min = ('' + (d.getMinutes())).padStart(2, '0');
            const sd = DateHelper.toSimpleDate(stringDateTime);
            const hr = `${hours}:${min}`;
            const sep = dateTimeSeparator ? `${dateTimeSeparator}` : ' ';
            return `${sd}${sep}${hr}`;
        }
        return '';
    }

    static toLocaleSimpleDate({ stringDate, locale }) {
        const l = locale === 'fr' ? 'fr-CA' : locale
        return new Date(stringDate).toLocaleDateString(l)
    }

    static toLongDate({ stringDate, locale }) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const d = new Date(stringDate);
        return d.toLocaleString(locale, options)
    }

    static toShortTime(stringDateTime) {
        if (stringDateTime) {
            const d = new Date(stringDateTime);
            const hours = ('' + (d.getHours())).padStart(2, '0');
            const min = ('' + (d.getMinutes())).padStart(2, '0');
            return `${hours}:${min}`;
        }
        return '';
    }

    static daysBetween(date1, date2) {
        if (date1 && date2) {
            const diffTime = new Date(date2) - new Date(date1);
            const days = Math.floor(diffTime / (1000 * 3600 * 24));
            return days;
        }
        return null;
    }

    static today() {
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }

    static dateTimeToLocaleJSON({ strDate, date }) {
        const d = date || new Date(strDate)
        const dt = new Date(d.getTime() + d.getTimezoneOffset() * 60000)
        return dt.toJSON()
    }

    static getDiffBetweenUtcDateAndNow({ fromUTCDate }) {
        const localDdt = Date.UTC(fromUTCDate.getFullYear(), fromUTCDate.getMonth(), fromUTCDate.getDate(), fromUTCDate.getHours(), fromUTCDate.getMinutes(), fromUTCDate.getSeconds());
        const diffTime = new Date() - new Date(localDdt);
        let days;
        let hours;
        let minutes = Math.floor(diffTime / (1000 * 60));
        if (minutes > 60) {
            hours = Math.floor(minutes / 60);
            minutes = minutes - (hours * 60);
        }
        if (hours > 24) {
            days = Math.floor(hours / 24);
            hours = hours - (days * 24);
        }
        return {
            minutes,
            hours,
            days
        }
    }
}
window.DateHelper = DateHelper
export default DateHelper
