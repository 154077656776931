<template>
    <div id="subscriptionConfirmationPanel" class="subscriptionConfirmationPanel">
        <div class="subscriptionConfirmationCard">
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <div class="scc-print" v-bind="attrs" v-on="on" @click="printInvoice">
                        <font-awesome-icon :icon="icons.print" style="color:dodgerblue"></font-awesome-icon>
                    </div>
                </template>
                <span>{{$t(`subscriptions.confirmation.print`)}}</span>
            </v-tooltip>
            <div class="scc-header">
                <div class="scc-thanks">{{$t('subscriptions.confirmation.thanks')}}</div>
                <div class="scc-orderNumberInfos">{{$t('subscriptions.confirmation.orderNumber')}} : <span class="scc-orderNumber">{{orderNumber}}</span></div>
                <div class="scc-sendto">{{$t('subscriptions.confirmation.sendTo')}} : <span class="scc-sendtoEmail">{{emailTo}}</span></div>
                <div class="scc-date">
                    <font-awesome-icon :icon="icons.date" style="color:dodgerblue;margin-right:5px"></font-awesome-icon>
                    {{$t('subscriptions.confirmation.orderDate')}} : {{orderStartDate}}</div>
            </div>
            <OrderDetails :order="order" :renewalDate="renewalDate"></OrderDetails>
            <div class="disclaimer">{{$t('subscriptions.plans.disclaimer')}}</div>
        </div>
    </div>
</template>

<script>
import { faCalendarAlt, faPrint } from '@fortawesome/pro-light-svg-icons';
import DateHelper from "../../infra/DateHelper";
import OrderDetails from './OrderDetails.vue'
export default {
    props: ['order'],
    data() {
        return {
            icons: {
                date: faCalendarAlt,
                print: faPrint
            }
        }
    },
    components: { OrderDetails },
    computed: {
        orderNumber() {
            return this.order ? this.order.number : '';
        },
        orderStartDate() {
            return this.order ? DateHelper.toLongDate({ stringDate: this.order.startDate, locale: this.$i18n.locale }) : ''
        },
        renewalDate() {
            return this.order ? this.order.endDate : ''
        },
        emailTo() {
            return this.order ? this.order.emailTo : ''
        }
    },
    methods: {
        printInvoice() {
            const prtHtml = document.getElementById('subscriptionConfirmationPanel').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }
            // Open the print window
            const WinPrint = window.open('', 'PRINT', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
                <head>
                ${stylesHtml}
                </head>
                <body>
                ${prtHtml}
                </body>
            </html>`);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        }
    }
}
</script>

<style scoped src="@/assets/css/subscriptionConfirmation.css">
</style>
