<template>
    <div class="pagePolicy">
        <div class="policyTitle">{{$t('policies.cookies.title')}}</div>
        <div class="policyLastUpdated">
            <font-awesome-icon :icon="icons.calendar" class="fa-fw fa-lg"></font-awesome-icon>
            {{$t('policies.lastUpdate')}} {{$t('policies.cookies.update')}}
        </div>
        <div class="policyDescription" v-html="$t('policies.cookies.message')"></div>
    </div>
</template>

<script>
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons"
export default {
    data () {
        return {
            icons: {
                calendar: faCalendarAlt
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/policies.scss";
</style>
